import React, { FC } from 'react'
import tw from 'twin.macro'

export const CardContent = tw.div`px-4 py-4 sm:px-6 sm:py-6`

export const Card: FC = ({ children, ...rest }) => (
  <div tw="sm:rounded-lg shadow-xs">
    <div tw="bg-white shadow-lg overflow-hidden sm:rounded-lg" {...rest}>
      {children}
    </div>
  </div>
)
