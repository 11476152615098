import { useEnvironment } from '../environment/Environment'
import { CaseType } from '../../content/onboarding/OnboardingStep'
import { useAnalytics } from './AnalyticsWrapper'

// List of events:
// https://developers.facebook.com/docs/facebook-pixel/reference
// https://developers.google.com/analytics/devguides/collection/gtagjs/events

export const useEmitClickedPrimaryCTA = () => {
  const { CONVERSION_CAMPAIGN_MAIN_CTA } = useEnvironment()
  const { registerGoogleEvent, registerFacebookCustomEvent } = useAnalytics()

  return () => {
    registerGoogleEvent(`conversion`, { send_to: CONVERSION_CAMPAIGN_MAIN_CTA })
    registerGoogleEvent('cta_click')
    registerFacebookCustomEvent('cta_click')
  }
}

export const useEmitSubmitContactInformation = () => {
  const { CONVERSION_CAMPAIGN_FORM } = useEnvironment()
  const { registerGoogleEvent, registerFacebookEvent } = useAnalytics()

  return () => {
    // conversion is for gtag backcompat
    registerGoogleEvent(`conversion`, { send_to: CONVERSION_CAMPAIGN_FORM })

    // submit form is for goptimize backcompat
    registerGoogleEvent(`submit form`)

    registerGoogleEvent(`generate_lead`)
    registerFacebookEvent('Lead')
  }
}

export const useEmitViewedPricing = () => {
  const { registerGoogleEvent, registerFacebookEvent } = useAnalytics()

  return () => {
    registerGoogleEvent(`view_item`, { event_label: 'pricing' })
    registerFacebookEvent('ViewContent', { content_name: 'pricing' })
  }
}

export const useEmitInitiatedCheckout = () => {
  const { registerGoogleEvent, registerFacebookEvent, registerHeapEvent } = useAnalytics()

  return (total: number, caseType: CaseType) => {
    const payload = {
      currency: 'GBP',
      value: total
    }

    registerGoogleEvent(`begin_checkout`, payload)
    registerFacebookEvent('InitiateCheckout', payload)
    registerHeapEvent('InitiateCheckout', { ...payload, caseType })
  }
}

export const useEmitCompletedPurchase = () => {
  const { registerGoogleEvent, registerFacebookEvent, registerHeapEvent } = useAnalytics()

  return (total: number, caseType: CaseType) => {
    const payload = {
      currency: 'GBP',
      value: total
    }

    // make purchase is for backcompat
    registerGoogleEvent(`make purchase`, payload)
    registerGoogleEvent(`purchase`, payload)
    registerFacebookEvent('Purchase', payload)
    // Price is for backcompat
    registerHeapEvent('Purchase', { ...payload, price: total, caseType })
    // window.heap.addUserProperties({LTV: `${total}`}) //LTV will overwrite if user checks out in the future. Maybe we can have a "total" row in db that is cumulative?
  }
}
