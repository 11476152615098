import React from 'react'
import { FC } from "react"
import { CardContent } from './Card'
import { SuccessMessage, SuccessMessageText } from './Message'
import tw from 'twin.macro'

const ListItem = tw.span`mb-4 sm:mb-6 last:mb-0 flex flex-row items-start text-sm sm:text-lg leading-6 sm:leading-7`
const ListNumber = tw.span`h-4 w-4 flex items-center justify-center text-xs sm:text-sm rounded-full bg-indigo-500 p-3 sm:p-4 text-white font-bold mr-4`

const Highlight = tw.span`px-2 inline-flex font-semibold rounded-full bg-indigo-100 font-bold text-blue-600`

type CompleteProps = {
    reset: () => void
    phoneNumber?: string
}


export const Complete: FC<CompleteProps> = ({ reset, phoneNumber }) => {

  return (
    <>
      <CardContent tw="p-4 sm:p-8">
        <SuccessMessage title={`Your case has been created and your solicitor is reviewing it now.`}>
          <SuccessMessageText tw="sm:hidden">
            We've started work on your case
          </SuccessMessageText>
        </SuccessMessage>

        <p tw="mt-6 font-medium text-xl sm:text-2xl">
          What happens next:
        </p>

        <ol tw="mt-4 list-none list-inside leading-6">
          <ListItem tw="font-medium text-blue-700">
            <ListNumber>1</ListNumber>
            {phoneNumber
            ?
            <>
            <p>
              We'll call you on <Highlight>{phoneNumber}</Highlight> to discuss your case.
            </p>
            </>
            :
            <>
            <p>
              We'll call you on the number you submitted to discuss your case.
            </p>
            </>
            }
          </ListItem>
          <ListItem>
            <ListNumber>2</ListNumber>
            You'll get instructions to sign into our online platform, where you can upload any relevant case files and evidence.
          </ListItem>
          <ListItem>
            <ListNumber>3</ListNumber>
            Your solicitor will assess your details, ask you questions and start work on your case.
          </ListItem>
        </ol>

        <p tw="mt-6 text-xs sm:text-sm">
          If we can't get in touch by phone, we'll send you a text or email.
        </p>

        <p tw="mt-4 text-xs sm:text-sm">
          If you want to cancel, you can do so for free at any time by
          emailing <a tw="text-blue-600" href="mailto:support@lawhive.co.uk">support@lawhive.co.uk</a> from
          the email address you signed up with.
        </p>
      </CardContent>

      <CardContent tw="p-4 sm:p-8 bg-gray-100">
        <div tw="flex-col">
          <p tw="font-medium text-xs uppercase tracking-wide">
            Have another issue?
          </p>
          <button
            tw="text-right text-indigo-500 hover:text-indigo-400 font-semibold text-sm"
            type="button"
            onClick={reset}
          >
            Start a new case &gt;
          </button>
        </div>
      </CardContent>
    </>
  )
}
