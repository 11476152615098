import { GatsbyLinkProps, Link } from 'gatsby'
import React, { FC } from 'react'
import { ButtonLink } from '../layout/Layout'

import { useAnalytics } from './AnalyticsWrapper'
import { useEmitClickedPrimaryCTA } from './Events'


interface FixedGatsbyLinkProps<T> extends GatsbyLinkProps<T> {
  key?: string | number
}

export const AnalyticsConversionLink: FC<FixedGatsbyLinkProps<any>> =
  ({ children, onClick, ref, ...props }) => {
    const registerConversion = useEmitClickedPrimaryCTA()

    return (
      <ButtonLink
        ref={ref as any}
        onClick={e => {
          if (typeof onClick === `function`) {
            onClick(e)
          }
          let redirect = true
          if (
            e.button !== 0 ||
            e.altKey ||
            e.ctrlKey ||
            e.metaKey ||
            e.shiftKey ||
            e.defaultPrevented
          ) {
            redirect = false
          }
          if (props.target && props.target.toLowerCase() !== `_self`) {
            redirect = false
          }

          registerConversion()

          return false
        }}
        {...props}
      >
        {children}
      </ButtonLink>
    )
  }
