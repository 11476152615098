import React, { FC } from 'react'
import tw from 'twin.macro'

type ErrorMessageProps = {
  title: string
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ title, children, ...props }) => (
  <div tw="p-4 sm:p-5 bg-red-50 rounded-lg flex-grow" {...props}>
    <div tw="flex flex-row">
      <svg xmlns="http://www.w3.org/2000/svg" tw="h-6 w-6 text-red-400 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
      </svg>
      <div tw="ml-5 text-xs sm:text-base">
        <h3 tw="text-sm sm:text-lg leading-6 font-semibold text-red-600">
          {title}
        </h3>
        {children}
      </div>
    </div>
  </div>
)

export const SuccessMessage: FC<{ title: string }> = ({ children, title, ...rest }) => (
  <div tw="p-4 sm:p-5 bg-green-50 rounded-lg flex-grow" {...rest}>
    <div tw="flex flex-row">
      <svg xmlns="http://www.w3.org/2000/svg" tw="h-6 w-6 text-green-400 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
      </svg>
      <div tw="ml-5 text-xs sm:text-base">
        <h1 tw="text-sm sm:text-lg leading-6 font-semibold text-green-600">
          {title}
        </h1>
        {children}
      </div>
    </div>
  </div>
)

export const InfoMessage: FC<{ title: string }> = ({ children, title, ...rest }) => (
  <div tw="p-4 sm:p-5 bg-blue-50 rounded-lg flex-grow" {...rest}>
    <div tw="flex flex-row">
      <svg xmlns="http://www.w3.org/2000/svg" tw="h-6 w-6 text-blue-400 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
      </svg>
      <div tw="ml-5 text-xs sm:text-base">
        <h1 tw="text-sm sm:text-lg leading-6 font-semibold text-blue-600">
          {title}
        </h1>
        {children}
      </div>
    </div>
  </div>
)

export const WarnMessage: FC<{ title: string }> = ({ children, title, ...rest }) => (
  <div tw="p-4 sm:p-5 bg-yellow-50 rounded-lg flex-grow" {...rest}>
    <div tw="flex flex-row">
      <svg xmlns="http://www.w3.org/2000/svg" tw="h-6 w-6 text-yellow-400 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
      </svg>
      <div tw="ml-5 text-xs sm:text-base">
        <h1 tw="text-sm sm:text-lg leading-6 font-semibold text-yellow-600">
          {title}
        </h1>
        {children}
      </div>
    </div>
  </div>
)

const MessageText = tw.p`mt-1 sm:mt-3 font-medium text-xs sm:text-sm`

export const ErrorMessageText = tw(MessageText)`text-red-500`
export const WarnMessageText = tw(MessageText)`text-yellow-500`
export const SuccessMessageText = tw(MessageText)`text-green-500`
export const InfoMessageText = tw(MessageText)`text-blue-500`
