import React, { ButtonHTMLAttributes, ChangeEvent, FC, InputHTMLAttributes, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'
import tw from 'twin.macro'

export const Form = tw.form`px-4 py-4 sm:px-6 sm:py-6`
export const FormSection = tw.div`mb-5 sm:mb-6 last:mb-0`
export const Label = tw.label`block text-sm leading-5 font-medium text-gray-700`

export const Input = tw.input`form-input block w-full px-4 sm:text-sm sm:leading-5`
export const TextArea = tw.textarea`form-textarea block w-full`

export const FormError = tw.p`text-xs text-red-500 font-medium sm:text-sm leading-6`

export const TextInput = React.forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
  >((props, ref) => (
  <div tw="relative rounded-md shadow-sm">
    <Input ref={ref} {...props} />
  </div>
))

export const FormButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => (
  <div tw="rounded-md shadow">
    <button
      tw="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-pink-500 hover:bg-pink-400 focus:outline-none focus:border-pink-600 focus:shadow-outline-pink transition duration-150 ease-in-out disabled:text-gray-50 disabled:cursor-not-allowed disabled:bg-pink-300"
      {...props}
    >
      {children}
    </button>
  </div>
)


type FormFieldProps = { id: string, label: string, control: JSX.Element, error?: FieldError }

export const FormField: FC<FormFieldProps> = ({ id, label, control, error }) => (
  <FormSection>
    <Label htmlFor={id}>
      {label}
    </Label>

    <div tw="mt-1">
      {control}
    </div>

    {error && (
      <FormError>{error.message}</FormError>
    )}
  </FormSection>
)


type CheckBoxGroupState = Set<string>

type CheckBoxGroupProps = {
  name: string
  config: {
    value: string
    children: ReactNode
  }[]
  value: CheckBoxGroupState
  onChange: (state: CheckBoxGroupState) => void
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const CheckBoxGroup: FC<CheckBoxGroupProps> = ({ name, value, config, onChange, onBlur }) => {
  const getNewState = (key: string, checked: boolean) => {
    const set = new Set(value)
    if(checked) {
      set.add(key)
    } else {
      set.delete(key)
    }
    return set
  }

  return (
    <>
      {config.map((r, i) => (
        <CheckItem
          key={i}
          name={name}
          id={`${name}-${r.value}`}
          value={r.value}
          checked={value.has(r.value) || false}
          onChange={e => onChange(getNewState(r.value, e.target.checked))}
          onBlur={onBlur}
        >
          {r.children}
        </CheckItem>
      ))}
    </>
  )
}

export const CheckItem: FC<InputHTMLAttributes<HTMLInputElement>> = ({ children, ...props }) => (
  <div tw="mb-2 last:mb-0">
    <label tw="inline-flex items-center">
      <input
        type="checkbox"
        tw="form-checkbox"
        {...props}
      />
      <span tw="ml-2">
        {children}
      </span>
    </label>
  </div>
)
